// import exp from "constants";
import { instance, StorageInstance } from '../config/api';

export async function storageBoxPrices() {
  return await instance.get(`/storage/price/`);
}

export async function getHomeData() {
  return await instance.get(`/page/home/`);
}
export async function getTestimonials() {
  return await instance.get(`/page/testimonials/`);
}
export async function getContactInfo() {
  return await instance.get(`/page/contactus-info/`);
}
export async function getWhyUs() {
  return await instance.get(`/page/why-us/`);
}
export async function getFaq(category) {
  return await instance.get(`/page/faq/?category=${category}`);
}

export async function getAllFaq() {
  return await instance.get(`/page/faq/`);
}
// How did uou find us
export async function getHdyfu() {
  return await instance.get(`page/hdyfy/`);
}
// get delivery served areas
export async function getLocations() {
  return await instance.get(`/store/locations/`);
}

// get footer data 
export async function getFooter() {
  return await instance.get(`/page/contactus-info/`);
}

export async function saveQuote(data) {
  return await instance.post(`/store/quote/`, data);
}

export async function saveNewsLetter(data) {
  return await instance.post(`/store/news-letter/`, data);
}

export async function rentalsPeriods() {
  return await instance.get(`/store/rentals/`);
}

export async function getPrivacyPolicy() {
  return await instance.get(`/page/policy/`);
}
export async function getTerms() {
  return await instance.get(`/page/terms/`);
}

export async function getStorageTerms() {
  return await instance.get(`/page/storage_terms/`);
}

export async function getAbout() {
  return await instance.get(`/page/why-us/`);
}
export async function getHomeMoving() {
  return await instance.get(`/page/home-moving/`);
}

export async function getOfficeMoving() {
  return await instance.get(`/page/office-moving/`);
}

// get box packages product
export async function getBoxPackages(category, sub_cate, rental) {
  return await instance.get(`/store/products/${category}/${sub_cate}/${rental}/`);
}

export async function checkZipCode(data) {
  return await instance.post(`/store/check-zipcode/`, data);
}

// Select Moving And Packing Supplies

export async function getSelectMovings(category, sub_cate) {
  return await instance.get(`/store/products/${category}/${sub_cate}/`);
}
export async function getSelectPackings(category, sub_cate) {
  return await instance.get(`/store/products/${category}/${sub_cate}/`);
}

export async function getDeliverWindows() {
  return await instance.get(`/store/delivery-windows/`);
}

export async function getExtraWork() {
  return await instance.get(`/store/extra-work/`);
}

//store status
export async function getStoreStatus() {
  return await instance.get(`/store/store_status/`);
}

export async function getStoreStatusMessage() {
  return await instance.get(`/store/store_status_message/`);
}

export async function getStoreTiming() {
  return await instance.get(`/store/store_schedule/`);
}

export async function placeOrder(data) {
  return await instance.post(`/order/save-stripe-info/`, data);
}

// Cart API
export async function addtoCart(data) {
  return await instance.post(`/order/cart/add`, data);
}

export async function getCart(subcategory, session) {
  return await instance.get(`order/cart/${subcategory}/${session}/`);
}
export async function getTotalCart(session) {
  return await instance.get(`order/cart/${session}/`);
}
//get packages rental wise
export async function getPackage(category, sub_category, rental) {
  return await instance.get(`store/products/${category}/${sub_category}/${rental}/`);
}

export async function delete_cart(cart_id) {
  return await instance.delete(`order/delete_cart/?id=${cart_id}`);
}

export async function getnewRental(data) {
  return await instance.post(`order/cart/update_rental`, data);
}

// Get Cart Total
export async function getTotal(session) {
  return await instance.get(`order/total/?session=${session}`);
}
// clear cart
export async function clearCart(session) {
  return await instance.get(`order/clear_cart/?session=${session}`);
}

// delivery and peackup
export async function addDelivery(data) {
  return await instance.post(`order/delivery/`, data);
}

// delivery details and pick up for preview
export async function getPreview(session) {
  return await instance.get(`order/preview/?order_id=${session}`);
}
export async function getDeliverDetails(session) {
  return await instance.get(`order/delivery/?order_id=${session}`);
}
export async function getPickupDetails(session) {
  return await instance.get(`order/pickup/?order_id=${session}`);
}
export async function getPersonaldetails(session) {
  return await instance.get(`order/personal_details/?order_id=${session}`);
}

// get time slot for delivery
export async function getDeliverSlots(session) {
  return await instance.get(`order/delivery_time/?session=${session}`);
}
export async function getPickupSlots(session) {
  return await instance.get(`order/pickup_time/?session=${session}`);
}
export async function addPickUp(data) {
  return await instance.post(`/order/pickup/`, data);
}
export async function getPickUp(session) {
  return await instance.get(`/order/pickup/?session=${session}`);
}
// add personal
export async function addPersonal(data) {
  return await instance.post(`/order/personal_details/`, data);
}
// add order details
export async function addOrder(data) {
  return await instance.post(`/order/add`, data);
}
// add contact us
export async function addContactus(data) {
  return await instance.post(`page/contact/`, data);
}
// add personal details
export async function addPaymentDetails(data) {
  return await instance.post(`order/add/payment_details`, data);
}
// disable days
export async function getUnavaliableDays() {
  return await instance.get(`/order/unavailable-dates/`);
}

// coupon code
export async function getCoupon(session, code, email) {
  return await instance.get(`order/total/?session=${session}&discount_code=${code}&email=${email}`);
}

//Stripe Checkout
export async function stripeCheckout(data) {
  return await instance.post(`order/create_checkout_session/`, data);
}

export async function getDays() {
  return await instance.get(`/order/unavailable-dates/`);
}
export async function getBlockedDays() {
  return await instance.get(`/order/block-day/`);
}
export async function validateOrderId(orderId) {
  return await instance.get(`/order/verify_order/${orderId}/`);
}

//validate coupon
export async function getValidateCoupon(code) {
  return await instance.get(`/order/check_discount/${code}/`);
}

//  Storage Service Functions Start

export async function validateStorageZipCode(data) {
  return await StorageInstance.post(`/common/storage/zipcode/validate`, data);
}

export async function createCustomer(data) {
  return await StorageInstance.post(`/customer/register`, data);
}

export async function login(data) {
  return await StorageInstance.post(`/customer/login`, data);
}

export async function updateCustomer(data) {
  return await StorageInstance.put(`/customer/update`, data);
}

export async function placeStorageOrder(data) {
  return await StorageInstance.post(`/storage_order/place`, data);
}

export async function storageStripeCheckoutSession(data) {
  return await StorageInstance.post(`/storage_order/create_checkout_session`, data);
}

export async function getCustomerStorageOrders(customer_id, filter) {
  return await StorageInstance.get(`/customer/orders/` + customer_id, { params: filter });
}

export async function getCustomerStorageSubscriptions(customer_id, filter) {
  return await StorageInstance.get(`/customer/subscriptions/` + customer_id, { params: filter });
}

export async function requestReset(data) {
  return await StorageInstance.post(`/customer/reset-password/request`, data);
}

export async function resetPwd(data) {
  return await StorageInstance.post(`/customer/reset-password`, data);
}

export async function getCustomerAddress() {
  return await StorageInstance.get(`/customer/me`);
}

export async function updateCustomerAddress(data) {
  return await StorageInstance.put(`/customer/update/profile`, data);
}

export async function getCustomer() {
  return await StorageInstance.get(`/customer/me`);
}

export async function getInventory(id) {
  return await StorageInstance.get(`/customer/inventory/` + id);
}

export async function createStorageDeliveryOrder(data) {
  return await StorageInstance.post(`/storage_order/delivery/place`, data);
}

export async function createDeliveryOrderCheckoutSession(data) {
  return await StorageInstance.post(`/storage_order/delivery/create_checkout_session`, data);
}

export async function getStorageOrder(id) {
  return await StorageInstance.get(`/storage_order/delivery/` + id);
}

export async function sendOrderReminderEmail(email, cartTotal, data) {
  return await StorageInstance.post(`/storage_order/order/reminder/`, { email: email, cartTotal: cartTotal, data });
}


export async function getStorageBlockDates() {
  console.log("getStorageBlockDates")
  return await instance.get(`storage/storage-block-dates/`);
}


//  Storage Service Functions End

export async function getAllBlogs(page, limit) {
  return await StorageInstance.get(`/blog/all?page=${page}&pageSize=${limit}`);
}

export async function getBlogById(id) {
  return await StorageInstance.get(`/blog/` + id);
}
export async function getDiscount() {
  return await instance.get(`order/default_discount/`);
}
//tax
export async function getTax() {
  return await instance.get(`settingadmin/tax/`);
}